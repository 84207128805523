import {
  Row,
  Col,
  Container,
  Card,
  OverlayTrigger,
  Tooltip,
  Breadcrumb
} from "react-bootstrap";
import { Link } from "react-router-dom";

import { app } from "../config/app";
import { PalliativePhasesNote } from "../components/PalliativePhasesNote";
import { visuals } from "../config/visuals";
import Banner from "../components/Banner";
import Filters from "../components/Filters";
import FiltersPanel from "../components/FiltersPanel";
import QdtComponent from "../components/QdtComponent";
import TableOrVisualisation from "../components/TableOrVisualisation";
import { costNwauLinks } from "../config/constants";
import KPI from "../components/KPI";
import Visualisation from "../components/Visualisation";

export const NWAUDetail = () => {
  const options = visuals;

  return (
    <div>
      <Breadcrumb className="breadcrumbs">
        <Link to="/" className="breadcrumb-item ps-1">Home</Link>
        <Link to="/periodic-insights" className="breadcrumb-item">Periodic insights</Link>
        <Breadcrumb.Item active>Cost Per NWAU</Breadcrumb.Item>
      </Breadcrumb>

      <Container fluid>
        <Row>
          <Banner title="Cost per NWAU" tabs={costNwauLinks} />
        </Row>

        <Row>
          <FiltersPanel app={app} />
        </Row>

        <Row className="content ">
          <Col md="1" className="border-r">
            <Filters />
          </Col>

          <Col >
            <Row className="mb-4 mb-md-2">
              <Col xs="12" md>
                <KPI borderRight app={app} options={options.NWAUPerRecordNational} />
              </Col>

              <Col xs="12" md>
                <KPI borderRight app={app} options={options.NWAUPerRecordPeerGroup} />
              </Col>

              <Col xs="12" md>
                <KPI borderRight app={app} options={options.NWAUPerRecordAllSelections} />
              </Col>

              <Col xs="12" md>
                <KPI borderRight app={app} options={options.TotalNWAUAllSelections} />
              </Col>

              <Col>
                <KPI app={app} options={options.TotalRecordsAllSelections} />
              </Col>
            </Row>
            <Row className="border-b mx-2"></Row>
            <Row>
              {/* NWAU per Separation Comparison */}
              <Col >
                <Visualisation borderRight name={"NWAUPerRecordComparison"} options={options.NWAUPerRecordComparison} withButtons={true} buttonOptions={options.NWAUPerRecordComparisonButtons} app={app} />
              </Col>

              <Col >
                <Row>
                  <Visualisation borderBottom name={"NWAUDriversOfGrowth"} options={options.NWAUDriversOfGrowth} withButtons={true} buttonOptions={options.NWAUDriversOfGrowthButtons} app={app} />
                </Row>
                <Row>
                  <Card>
                    <div className="title-wrap">
                      <OverlayTrigger
                        overlay={
                          <Tooltip id="tooltip-top">
                            {options.NWAUPerRecordLineChart.tooltip!.text}
                          </Tooltip>
                        }
                      >
                        <div
                          className="tool-tip"
                          style={{
                            display: options.NWAUPerRecordLineChart.tooltip!.enabled
                              ? "block"
                              : "none",
                          }}
                        ></div>
                      </OverlayTrigger>
                    </div>
                    <div className="object-wrap ">
                      <div className="sm-btn">
                        <QdtComponent options={options.NWAUPerRecordLineChartButtons.options} app={app} />
                      </div>
                      <TableOrVisualisation visualisation='NWAUPerRecordLineChart' app={app} />
                    </div>
                  </Card>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <PalliativePhasesNote />
        </Row>
      </Container>
    </div>
  );
};

