import {
  Row,
  Col,
  Container,
  Card,
  OverlayTrigger,
  Tooltip,
  Breadcrumb
} from "react-bootstrap";
import { Link } from "react-router-dom";

import { hacApp } from "../config/app";
import { visuals } from "../config/visuals";
import { HACFootNote } from "../components/HACFootnote";
import Banner from "../components/Banner";
import HacFilters from "../components/HacFilters";
import FiltersPanel from "../components/FiltersPanel";
import QdtComponent from "../components/QdtComponent";
import TableOrVisualisation from "../components/TableOrVisualisation";
import { PalliativePhasesNote } from "../components/PalliativePhasesNote";
import { hospitalComplicationsLinks } from "../config/constants";
import KPI from "../components/KPI";
import Visualisation from "../components/Visualisation";

export const HACTrends = () => {
  const options = visuals;

  return (
    <div>
      <Breadcrumb className="breadcrumbs">
        <Link to="/" className="breadcrumb-item ps-1">Home</Link>
        <Link to="/periodic-insights" className="breadcrumb-item">Periodic insights</Link>
        <Breadcrumb.Item active>Hospital Acquired Complications</Breadcrumb.Item>
      </Breadcrumb>

      <Container fluid>
        <Row>
          <Banner title="Hospital Acquired Complications" tabs={hospitalComplicationsLinks} />
        </Row>

        <Row>
          <FiltersPanel app={hacApp} />
        </Row>

        <Row className="content ">
          <Col md="1" className="border-r mb-5 mb-lg-0">
            <HacFilters />
          </Col>

          <Col>
            <Row className="border-b">
              <Col className="mb-2">
                {/* number of hac */}
                <KPI borderRight app={hacApp} options={options.HACCount} />
              </Col>

              <Col className="pe-0">
                {/* hac rate */}
                <KPI borderRight app={hacApp} options={options.HACRate} />
              </Col>

              <Col>
                {/* total episodes */}
                <KPI app={hacApp} options={options.HACEpisodes} />
              </Col>
            </Row>

            <Row className="border-b">
              <Col md="6" className="border-r">
                {/* average length of stay */}
                <Visualisation
                  name={"AveLOSLineChart"}
                  options={options.AveLOSLineChart}
                  withButtons={false}
                  app={hacApp} />
              </Col>

              <Col md="6">
                {/* rate of long stay outliers */}
                <Visualisation
                  name={"RateLongStayLineChart"}
                  options={options.RateLongStayLineChart}
                  withButtons={false}
                  app={hacApp} />
              </Col>
            </Row>

            <Row>
              <Col md="4" className="border-r">
                {/* charlson score */}
                <Visualisation
                  name={"CharlsonScoreChart"}
                  options={options.CharlsonScoreChart}
                  withButtons={false}
                  app={hacApp} />
              </Col>

              <Col md="8">
                {/* hac activity */}
                <Visualisation
                  name={"HACActivityBarChart"}
                  options={options.HACActivityBarChart}
                  withButtons={true}
                  buttonOptions={options.HACActivityBarChartButtons}
                  app={hacApp} />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <HACFootNote />
        </Row>
      </Container>
    </div>
  );
};

