import { Row, Col, Container } from "react-bootstrap";

import styles from './styles/Footer.module.scss'

const Footer = () => {
  return (
    <Container fluid className={`d-flex align-items-end ${styles['footer']} pb-2`}>
      <Row className="d-flex align-items-end w-100 pb-4 px-4">
        <Col sm="12" md={"auto"} className="p-0 me-5">
          <a
            href={"https://www.ihacpa.gov.au"}
            target="_blank"
            className="bold"
          >
            IHACPA website
          </a>

        </Col>
        <Col sm="12" md={"auto"} className="me-auto p-0">
          <p className={`mb-0 ${styles['fira-sans']} bold`}>Contact us: <a className={`bold ${styles['fira-sans']}`} href="mailto:enquiries.ihacpa@ihacpa.gov.au">enquiries.ihacpa@ihacpa.gov.au</a></p>
        </Col>
        <Col sm="12" md="auto" className="p-0">
          <p className="mb-0">
            &#169; Copyright {new Date().getFullYear()} IHACPA
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default Footer;
