import { QdtSelections } from "qdt-components";

// import { app, hacApp } from "../config/app";
import QdtComponent from "../components/QdtComponent";
import { AppConfig } from "../config/app";

export interface SelectionsProps {
  app: AppConfig
}

const Selections: React.FC<SelectionsProps> = (props) => {
  return (
    <QdtComponent
      component={QdtSelections}
      properties={{
        qSelectionObjectDef: {},
      }}
      appIndex={1}
      app={props.app}
    />
  );
};

export default Selections;
