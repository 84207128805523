import { Container, Row, Navbar, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useLocation } from "react-router";
import React from "react";

import Export from "../images/export.svg";
import styles from "./styles/Banner.module.scss"

export interface BannerProps {
  title: string;
  tabs: { name: string, link: string }[]
}

const BannerFunction: React.FC<BannerProps> = (props) => {
  const routeLocation = useLocation();

  return (
    <>
      <Container fluid>
        <Row>
          <div className="banner bold">
            <div className="banner-text">
              <h1 className="my-2">{props.title}</h1>

              <Navbar className={`${styles['navbar']}`}>
                <Row className="mb-2">
                  {props.tabs.map(({ name, link }) => (
                    <Col xs={12} sm="auto" className="pe-0 me-5 mb-4 mb-sm-0">
                      <Link
                        key={link}
                        to={link}
                        className={`${routeLocation.pathname === link
                          ? styles["active"]
                          : `${styles["inactive"]} fancy-underline`
                          }`}
                      >
                        {name}
                      </Link>
                    </Col>))}
                </Row>
              </Navbar>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default BannerFunction;
