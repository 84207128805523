import { Container, Row, Navbar, Col, Card, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useLocation } from "react-router";
import React from "react";

import Export from "../images/export.svg";
import styles from "./styles/Banner.module.scss"
import QdtComponent from "./QdtComponent";
import { VisualisationShape } from "../config/visuals";
import { AppConfig } from "../config/app";
import TableOrVisualisation from "./TableOrVisualisation";

export interface VisualisationProps {
  name: string;
  options: VisualisationShape;
  buttonOptions?: VisualisationShape;
  borderRight?: boolean;
  borderBottom?: boolean;
  showFlipButton?: boolean;
  showHoverIcon?: boolean;
  withButtons: boolean;
  app: AppConfig;
}

const Visualisation: React.FC<VisualisationProps> = ({ showHoverIcon, showFlipButton, options, borderBottom, borderRight, app, name, withButtons, buttonOptions }) => {
  const routeLocation = useLocation();

  return (
    <Card className={`${borderRight ? "border-r" : ""} ${borderBottom ? "border-b" : ""} mb-5 mb-md-0`}>
      {showHoverIcon && <div className="title-wrap">
        <OverlayTrigger
          overlay={
            <Tooltip id="tooltip-top">
              {options.tooltip!.text}
            </Tooltip>
          }
        >
          <div
            className="tool-tip"
            style={{
              display: options.tooltip!.enabled
                ? "block"
                : "none",
            }}
          ></div>
        </OverlayTrigger>
      </div>}
      <div className="object-wrap">
        {withButtons && <QdtComponent options={buttonOptions?.options} app={app} />}
        <TableOrVisualisation visualisation={name} app={app} showFlipButton={showFlipButton} />
      </div>
    </Card>
  );
};

Visualisation.defaultProps = {
  showFlipButton: true,
  showHoverIcon: true,
};

export default Visualisation;