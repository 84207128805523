import {
  Row,
  Col,
  Container,
  Card,
  Breadcrumb,
  BreadcrumbItem
} from "react-bootstrap";
import { Link } from "react-router-dom";

import Arrow from "../images/arrowMidnight.svg";
import NwauIcon from "../images/nwauIcon.svg";
import HospitalAcquiredIcon from "../images/hospitalAcquiredIcon.svg";
import HospitalAvoidableIcon from "../images/hospitalAvoidableIcon.svg";
import styles from "./styles/PIMenu.module.scss"

export const PIMenu = () => {
  const focusArea = ['Costing', 'Activity', 'Pricing'];

  return (
    <Container fluid className="wrapper">
      <Row md="12" className="PImenu mx-2">
        <Col>
          <Row className={"mb-5"}>
            <Col>
              <h2 className="bold">Periodic insights</h2>
            </Col>
          </Row>
          <Row>
            <Col xs={12} xl={4}>
              <Link to="/periodic-insights/overview">
                <Card className={`m-0 p-4 px-4 py-5 h-100 ${styles['enabled']}`}>
                  <Card.Body>
                    <Card.Img
                      src={NwauIcon}
                      alt="Cost per NWAU"
                      width="75"
                      height="75"
                    />
                    <Card.Title className="h3 bold">
                      Cost per national weighted activity unit (NWAU)
                    </Card.Title>
                    <Card.Img
                      src={Arrow}
                      alt="Go to Cost per NWAU"
                      width="55"
                      height="55"
                    />
                  </Card.Body>
                  <Card.Text className="mt-3">
                    The average cost used to deliver health service activity expressed as a common unit.
                  </Card.Text>
                </Card>
              </Link>
            </Col>

            <Col xs={12} xl={4}>
              <Link to="/periodic-insights/hac-trends">
                <Card className={`mx-0 mt-lg-0 mb-4 px-4 py-5 h-100 ${styles['enabled']}`}>
                  <Card.Body>
                    <Card.Img
                      src={HospitalAcquiredIcon}
                      alt="Cost per NWAU"
                      width="75"
                      height="75"
                    />
                    <Card.Title className="h3 bold">
                      Hospital acquired complications
                    </Card.Title>
                    <Card.Img
                      src={Arrow}
                      alt="Go to HAC Trends"
                      width="55"
                      height="55"
                    />
                  </Card.Body>
                  <Card.Text className="mt-3">
                    A complication experienced by a patient during their stay at the hospital and for which clinical risk mitigation strategies may reduce, but not necessarily eliminate the risk of that complication occurring.
                  </Card.Text>
                </Card>
              </Link>
            </Col>

            <Col xs={12} xl={4}>
              <Link to="/periodic-insights/ahr-trends">
              <Card className={`mx-0 mt-4 mt-lg-0 p-4 px-4 py-5 h-100 ${styles['enabled']}`}>
                <Card.Body>
                  <Card.Img
                    src={HospitalAvoidableIcon}
                    alt="AHR Trends"
                    width="75"
                    height="75"
                  />
                  <Card.Title className="h3 bold">
                    Avoidable Hospital Readmissions
                  </Card.Title>
                  <Card.Img
                    src={Arrow}
                    alt="Go to AHR Trends"
                    width="55"
                    height="55"
                  />
                </Card.Body>
                <Card.Text className="mt-3">
                  When a patient who has been discharged from hospital (the index admission) is readmitted within a condition-specific time frame. The readmission episode is critically related to the index admission and the reason for readmission was potentially avoidable through clinical management and/or appropriate discharge planning.
                </Card.Text>
              </Card>
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

