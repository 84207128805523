export const costNwauLinks = [
  {
    name: "Overview",
    link: "/periodic-insights/overview"
  },
  {
    name: "Cost detail",
    link: "/periodic-insights/cost-detail"
  },
  {
    name: "NWAU detail",
    link: "/periodic-insights/nwau-detail"
  }
];

export const hospitalComplicationsLinks = [
  {
    name: "HAC trends",
    link: "/periodic-insights/hac-trends"
  },
  {
    name: "HAC activity",
    link: "/periodic-insights/hac-activity"
  },
  {
    name: "NWAU adjustments",
    link: "/periodic-insights/nwau-adjustments"
  },
];

export const ahrLinks = [
  {
    name: "AHR trends",
    link: "/periodic-insights/ahr-trends"
  },
  {
    name: "AHR activity",
    link: "/periodic-insights/ahr-activity"
  },
  {
    name: "NWAU adjustments",
    link: "/periodic-insights/ahr-nwau-adjustments"
  },
];

export const GA_TRACKING_ID = "G-RGR7JD96QN";
