export interface AttributesShape {
  id: string
  height: number
}

export interface ToolTip {
  enabled: boolean
  text: string
}

export interface VisualisationShape {
  options: AttributesShape
  tooltip?: ToolTip
  table?: AttributesShape
}

export interface VisualsShape {
  [key: string]: VisualisationShape
}

export const visuals: VisualsShape =
{
  YearFilter: {
    options: {
      id: "Ybvb",
      height: 50,
    },
  },

  YearFilterHAC: {
    options: {
      id: "PvSBp",
      height: 50,
    },
  },

  YearFilterAHR: {
    options: {
      id: "BNjPUY",
      height: 50,
    },
  },

  StateFilter: {
    options: {
      id: "pZETPKW",
      height: 50,
    },
  },

  StateFilterHAC: {
    options: {
      id: "pgfAj",
      height: 50,
    },
  },

  StateFilterAHR: {
    options: {
      id: "tCmSbMC",
      height: 50,
    },
  },

  LHNFilter: {
    options: {
      id: "pNkvb",
      height: 50,
    },
  },

  LHNFilterHAC: {
    options: {
      id: "ptjcyzA",
      height: 50,
    },
  },

  LHNFilterAHR: {
    options: {
      id: "zhXZ",
      height: 50,
    },
  },

  HospitalFilter: {
    options: {
      id: "jrnsx",
      height: 50,
    },
  },

  HospitalFilterHAC: {
    options: {
      id: "hyeuAra",
      height: 50,
    },
  },

  HospitalFilterAHR: {
    options: {
      id: "BjSFdR",
      height: 50,
    },
  },

  StreamFilter: {
    options: {
      id: "XXUuVZL",
      height: 50,
    },
  },

  PeerGroupFilter: {
    options: {
      id: "whuk",
      height: 50,
    },
  },

  PeerGroupFilterHAC: {
    options: {
      id: "fSXCKN",
      height: 50,
    },
  },

  PeerGroupFilterAHR: {
    options: {
      id: "HEPuxhB",
      height: 50,
    },
  },

  ComplicationGroupFilterHAC: {
    options: {
      id: "Wjdqch",
      height: 50,
    },
  },

  ReadmissionGroupFilterAHR: {
    options: {
      id: "ULTmcJf",
      height: 50,
    },
  },

  AgeFilterHAC: {
    options: {
      id: "SJsceew",
      height: 50,
    },
  },

  AgeFilterAHR: {
    options: {
      id: "cJEmmYB",
      height: 50,
    },
  },

  MonthFilterHAC: {
    options: {
      id: "fSxmVe",
      height: 50,
    },
  },

  MonthFilterAHR: {
    options: {
      id: "mkJfGP",
      height: 50,
    },
  },

  CostFilter: {
    options: {
      id: "FKqmGp",
      height: 50,
    },
  },

  CostDirectOrOverHeadFilter: {
    options: {
      id: "JkpNT",
      height: 50,
    },
  },

  ClassificationFilter: {
    options: {
      id: "kJnLmN",
      height: 100,
    },
  },

  PrincipalDiagnosisFilter: {
    options: {
      id: "ZKQGgBX",
      height: 50,
    },
  },

  SRGFilter: {
    options: {
      id: "BxZ",
      height: 50,
    },
  },

  NWAUPerRecordNational: {
    options: {
      id: "mxcU",
      height: 130,
    },
    tooltip: {
      enabled: true,
      text: "The sum of all activity expressed as a common unit divided by the total number of records, nationally.",
    }
  },

  NWAUPerRecordAllSelections: {
    options: {
      id: "pxzHJ",
      height: 130,
    },
    tooltip: {
      enabled: true,
      text: "The sum of all activity expressed as a common unit divided by the total number of records as a common unit, for all current selections.",
    },
  },

  NWAUPerRecordPeerGroup: {
    options: {
      id: "LXaefe",
      height: 130,
    },
    tooltip: {
      enabled: true,
      text: "The sum of all activity expressed as a common unit divided by the total number of records, at a peer group level for the selected or underlying peer group selection.",
    },
  },

  TotalNWAUAllSelections: {
    options: {
      id: "LjVXy",
      height: 130,
    },
    tooltip: {
      enabled: true,
      text: "The collective sum of all activity expressed as a common unit, native to the selected year.",
    },
  },

  TotalRecordsAllSelections: {
    options: {
      id: "Cupvx",
      height: 130,
    },
    tooltip: {
      enabled: true,
      text: "The total number of costed records for the current selections, as defined in the glossary (see'Data included in the NBP').",
    },
  },

  NWAUPerRecordComparison: {
    options: {
      id: "YvSHyTA",
      height: 500
    },

    table: {
      id: "PLfMtj",
      height: 500
    },

    tooltip: {
      enabled: true,
      text: "This graph compares the total number of records (x-axis) with the NWAU per record (y-axis) by state (default), local hospital network, hospital and stream against the average total records (vertical axis) and average NWAU per record (horizontal axis) for the current selections.",
    },
  },

  //Scatter Chart - Button Selections
  NWAUPerRecordComparisonButtons: {
    options: {
      id: "BEfwu",
      height: 70,
    },
    tooltip: {
      enabled: true,
      text: "Info about this metric",
    },
  },

  NWAUPerRecordLineChartButtons: {
    options: {
      id: "GbLY",
      height: 40,
    },
  },

  NWAUPerRecordLineChart: {
    options: {
      id: "JmJ",
      height: 300,
    },
    table: {
      id: "QjPdX",
      height: 300,
    },

    tooltip: {
      enabled: true,
      text: "Line graph comparing NWAU per record at monthly intervals from 2017-18 to 2020-21. ",
    },
  },

  NWAUDriversOfGrowthButtons: {
    options: {
      id: "cmXKeGE",
      height: 70,
    },

    tooltip: {
      enabled: true,
      text: "Info about this metric",
    },
  },


  NWAUDriversOfGrowth: {
    options: {
      id: "dJdfa",
      height: 300,
    },

    table: {
      id: "qrPCja",
      height: 300
    },

    tooltip: {
      enabled: true,
      text: "This waterfall graph displays the impact of patient-level variables including growth in records, between ADRG changes, within ADRG changes, length of stay in hospital, hospital demographic, and clinical and private sector adjustments on NWAU growth, as compared to the previous year.",
    },
  },

  CostPerNWAUNational: {
    options: {
      id: "psLUFfp",
      height: 130,
    },
    tooltip: {
      enabled: true,
      text: "The average cost used to deliver health service activity expressed as a common unit, nationally. ",
    },
  },

  CostPerNWAUBreakDown: {
    options: {
      id: "dytzzVr",
      height: 450,
    },

    table: {
      id: "TXssT",
      height: 300
    },

    tooltip: {
      enabled: true,
      text: "Values based on current selections compared to the same selections in the previous year. ",
    },
  },

  CostPerNWAUAllSelections: {
    options: {
      id: "SjNJ",
      height: 130,
    },
    tooltip: {
      enabled: true,
      text: "The average cost used to deliver health service activity expressed as a common unit, for all selections. ",
    },
  },

  CostPerNWAUPeerGroup: {
    options: {
      id: "jamY",
      height: 130,
    },
    tooltip: {
      enabled: true,
      text: "The average cost used to deliver health service activity expressed as a common unit, at a peer group level for the selected or underlying peer group selection. ",
    },
  },

  CostPerNWAUBarChartButtons: {
    options: {
      id: "kJa",
      height: 75,
    },
    tooltip: {
      enabled: true,
      text: "Info about this metric",
    },
  },

  CostPerNWAUBarChart: {
    options: {
      id: "nwDprp",
      height: 350,
    },

    table: {
      id: "fnXevjS",
      height: 200
    },

    tooltip: {
      enabled: true,
      text: "Cost per NWAU for each state (default), local hospital network, hospital and all streams, with an option to toggle between tabs.",
    },

  },

  NWAUPerSeparationAllSelections: {
    options: {
      id: "XLJZYDG",
      height: 100,
    },
    tooltip: {
      enabled: true,
      text: "Total native NWAU for the year selected divided by the total records.",
    },
  },

  NWAUPerSeparationComparisonNumbers: {
    options: {
      id: "MVqmn",
      height: 130,
    },
    tooltip: {
      enabled: true,
      text: "Info about this metric",
    },
  },

  NWAUPerSeparationComparisonTitles: {
    options: {
      id: "BcBZsz",
      height: 130,
    },
    tooltip: {
      enabled: true,
      text: "Info about this metric",
    },
  },
  CostPerSeparationAllSelections: {
    options: {
      id: "NETfNKP",
      height: 100,
    },
    tooltip: {
      enabled: true,
      text: "Total cost for the financial year divided by the total records.",
    },
  },

  CostPerSeparationComparisonNumbers: {
    options: {
      id: "CaBavA",
      height: 130,
    },
    tooltip: {
      enabled: true,
      text: "Info about this metric",
    },
  },
  CostPerSeparationComparisonTitles: {
    options: {
      id: "BcBZsz",
      height: 130,
    },
    tooltip: {
      enabled: true,
      text: "Info about this metric",
    },
  },

  CostPerNWAUKPI: {
    options: {
      id: "nmypmL",
      height: 130,
    },

    tooltip: {
      enabled: true,
      text: "The average cost used to deliver health service activity expressed as a common unit, nationally. ",
    }
  },

  CostPerNWAUPeerGroupKPI: {
    options: {
      id: "KzmPUY",
      height: 130,
    },

    tooltip: {
      enabled: true,
      text: "The average cost used to deliver health service activity expressed as a common unit, at a peer group level for the selected or underlying peer group selection. ",
    }
  },

  CostPerNWAUAllSelectionsKPI: {
    options: {
      id: "kJYpjL",
      height: 130,
    },

    tooltip: {
      enabled: true,
      text: "The average cost used to deliver health service activity expressed as a common unit, for all selections. ",
    }
  },

  TotalCostKPI: {
    options: {
      id: "KCtMH",
      height: 130,
    },

    tooltip: {
      enabled: true,
      text: "The sum of the direct and overhead costs used to deliver patient care, for all current selections.",
    }
  },


  CostPerNWAULineChart: {
    options: {
      id: "PXRL",
      height: 270,
    },

    table: {
      id: "WEaRZUE",
      height: 300
    },

    tooltip: {
      enabled: true,
      text: "This line graph compares the cost per NWAU at monthly intervals from 2017-18 to 2019-20. ",
    },
  },

  CostPerNWAULineChartButtons: {
    options: {
      id: "HjZgAfd",
      height: 40,
    },

    tooltip: {
      enabled: true,
      text: "Info about this metric",
    }
  },


  CostPerNWAUScatterChartButtons: {
    options: {
      id: "EdcfUX",
      height: 75,
    },

    tooltip: {
      enabled: true,
      text: "info about this metric",
    }
  },

  CostPerNWAUScatterChart: {
    options: {
      id: "NBGzg",
      height: 400,
    },

    table: {
      id: "XWmh",
      height: 300
    },

    tooltip: {
      enabled: true,
      text: "This graph compares the total number of records (x-axis) with the average cost per NWAU (y-axis) by state (default), local hospital network, hospital, stream and classification (once stream has been selected), against the average total records (vertical axis) and average cost per NWAU (horizontal axis) for the current selections.",
    },
  },

  CostPerNWAUBarChartCostDetail: {
    options: {
      id: "ZpypqzS",
      height: 260,
    },

    table: {
      id: "tpAaZ",
      height: 250
    },

    tooltip: {
      enabled: true,
      text: `This graph compares the average cost per NWAU across all cost buckets (direct and overhead).`,
    },
  },

  CostPerNWAUCostBucketSelection: {
    options: {
      id: "mGpxhC",
      height: 70,
    },

    tooltip: {
      enabled: true,
      text: "Info about this metric",
    },
  },

  //Start of HAC Page
  //NWAU Adjustments Page
  NWAUAdjustments: {
    options: {
      id: "BKjrLum",
      height: 130,
    },

    tooltip: {
      enabled: true,
      text: "Total NWAU deducted due to HAC adjustment.",
    }
  },

  PercentageAdjustments: {
    options: {
      id: "tQfj",
      height: 130,
    },

    tooltip: {
      enabled: true,
      text: "NWAU deducted due to HAC adjustment, as a percentage of total NWAU.",
    }
  },

  HACCount: {
    options: {
      id: "vjYcPJ",
      height: 130,
    },

    tooltip: {
      enabled: true,
      text: "The number of HACs (some HAC categories do not incur adjustments, see the User Guide for more details).",
    }
  },

  HACEpisodes: {
    options: {
      id: "GVUjewX",
      height: 130,
    },

    tooltip: {
      enabled: true,
      text: "The number of episodes with one or more HACs.",
    }
  },

  NWAUHACScatterChartButtons: {
    options: {
      id: "ApXtFz",
      height: 75,
    }
  },

  NWAUHACScatterChart: {
    options: {
      id: "nFxjb",
      height: 260,
    },

    table: {
      id: "PRZepmE",
      height: 260
    },

    tooltip: {
      enabled: true,
      text: `NWAU deducted due to HAC adjustment by state, local health network, hospital, and AIHW hospital peer group.`,
    },
  },

  HACRiskCateoryChart: {
    options: {
      id: "MKWPQnS",
      height: 345,
    },

    table: {
      id: "nSaHkh",
      height: 345
    },

    tooltip: {
      enabled: true,
      text: `A comparison of the observed and expected HAC rate by Risk complexity group. See the User Guide for details on how these categories are defined.`,
    },
  },

  NWAUbyHACGroupBarChartButtons: {
    options: {
      id: "bAppA",
      height: 75,
    }
  },

  NWAUbyHACGroupBarChart: {
    options: {
      id: "HJKKwe",
      height: 343,
    },

    table: {
      id: "swjYYw",
      height: 343
    },

    tooltip: {
      enabled: true,
      text: `These three charts show HAC and NWAU adjustment by complication group: (1) the number of episodes with HAC and the total NWAU HAC, (2) a year-on-year compariosn of NWAU adjustmnets due to HACs and (3) a year-on-year comparison of deduction per HAC.`,
    },
  },

  HACgroupLegendTable: {
    options: {
      id: "gBcm",
      height: 343,
    }
  },

  NWAUandHACLineChartButtons: {
    options: {
      id: "UWgPxz",
      height: 75,
    }
  },

  //NWAU by episodes with HAC adjustments
  NWAUandHACLineChart: {
    options: {
      id: "PKW",
      height: 260,
    },

    table: {
      id: "hMbBWVB", 
      height: 250
    },

    tooltip: {
      enabled: true,
      text: `These three charts displau episodes with HACs and NWAU adjustments over time: (1) total HAC adjustment by quarter through years, (2) total NWAU HAC adjustments and the number of episodes with HACs by quarter, (3) total NWAU adjustments as a percentage of total NWAU** accrued by episodes with and without a HAC.`,
    },
  },

  HACRate: {
    options: {
      id: "TdbMxp",
      height: 130,
    },

    tooltip: {
      enabled: true,
      text: "The number of HACs per 10,000 episodes that satisfy the HAC eligibility criteria (for details, see the User Guide).",
    }
  },

  ExpectedHACRate: {
    options: {
      id: "QmRTwsa",
      height: 130,
    },

    tooltip: {
      enabled: true,
      text: "The expected number of HACs per 10,000 episodes that satisfy the HAC eligibility criteria (for details, see the User Guide).",
    }
  },

  ExpectedHACCount: {
    options: {
      id: "tdjxsN",
      height: 130,
    },

    tooltip: {
      enabled: true,
      text: "An estimate of the number of HACs based on the casemix of the episodes satisfying the user's filter criteria. For details on the calculation of this estimate, see the User Guide.",
    }
  },

  HACRateBarChartButtons: {
    options: {
      id: "UchnA",
      height: 75,
    }
  },

  HACRateBarChart: {
    options: {
      id: "prmZB",
      height: 260,
    },

    table: {
      id: "sLpzn",
      height: 250
    },

    tooltip: {
      enabled: true,
      text: `The HAC rate by state, local hospital network, hospital, or AIHW hospital peer group.`,
    },
  },

  HACEpisodesLineChartButtons: {
    options: {
      id: "kHmbYB",
      height: 75,
    }
  },

  //Episodes with HACs over time
  HACEpisodesLineChart: {
    options: {
      id: "CjtHshm",
      height: 260,
    },

    table: {
      id: "beknJq",
      height: 250
    },

    tooltip: {
      enabled: true,
      text: `The 'Year comparison' tab shows the number of episodes with HACs each quarter over time. The 'Against expected' tab compares the number of HACs to the expected number of HACs.`,
    },
  },

  HACbyGroupLegendTable: {
    options: {
      id: "QZwPNP",
      height: 343,
    }
  },

  HACbyGroupBarChart: {
    options: {
      id: "pPcVH", 
      height: 343,
    },

    table: {
      id: "HtmnkDF",
      height: 343
    },

    tooltip: {
      enabled: true,
      text: `The number of HACs within each category compared to a modelled estimate of HACs, based on casemix. Select a HAC category to see the number of HACs within each subcategory, compared to the previous year.`,
    },
  },

  AveLOSLineChart: {
    options: {
      id: "urTkQ", 
      height: 343,
    },

    table: {
      id: "pGmemf",
      height: 343
    },

    tooltip: {
      enabled: true,
      text: `A comparison between the average length of stay for episodes with and without a HAC.`,
    },
  },

  RateLongStayLineChart: {
    options: {
      id: "xQXvkpU", 
      height: 343,
    },

    table: {
      id: "haJvAc",
      height: 343
    },

    tooltip: {
      enabled: true,
      text: `A comparison of the rate of long stay outliers between episodes with and without a HAC.`,
    },
  },

  CharlsonScoreChart: {
    options: {
      id: "bnhd", 
      height: 343,
    },

    table: {
      id: "fLPQVH",
      height: 343
    },

    tooltip: {
      enabled: true,
      text: `The comparison of the proportion of episodes with a mild, moderate, or severe Charlson score between episodes with and without a HAC. See the User Guide for definitions of Charlson score and the groupings used.`,
    },
  },

  HACActivityBarChartButtons: {
    options: {
      id: "CdULhS",
      height: 75,
    }
  },

  HACActivityBarChart: {
    options: {
      id: "mMKmh",
      height: 260,
    },

    table: {
      id: "JcgPX",
      height: 250
    },

    tooltip: {
      enabled: true,
      text: `The proportion of HACs contained in each HAC category. The user can compare selected jurisdictions, LHNs, hospitals, or peer groups using the tabs above the graph.`,
    },
  },

  //Start of AHR dashboard
  AHRNWAUAdjustments: {
    options: {
      id: "emfEYm",
      height: 130,
    },

    tooltip: {
      enabled: true,
      text: "Total NWAU deducted due to AHR adjustment.",
    }
  },

  AHRPercentageAdjustments: {
    options: {
      id: "SZKAVh",
      height: 130,
    },

    tooltip: {
      enabled: true,
      text: "NWAU deducted due to AHR adjustment, as a percentage of total NWAU.",
    }
  },

  AHRCount: {
    options: {
      id: "FzhZMBJ",
      height: 130,
    },

    tooltip: {
      enabled: true,
      text: "The number of Avoidable Hospital Readmissions.",
    }
  },

  AHRSeparations: {
    options: {
      id: "RtpmDL",
      height: 130,
    },

    tooltip: {
      enabled: true,
      text: "The number of episodes satisfying the definition of at least one AHR category.",
    }
  },

  NWAUAHRScatterChartButtons: {
    options: {
      id: "EaHvJub",
      height: 75,
    }
  },

  NWAUAHRScatterChart: {
    options: {
      id: "ZwGWyJq", 
      height: 260,
    },

    table: {
      id: "vEsPXx",
      height: 260
    },

    tooltip: {
      enabled: true,
      text: `NWAU deducted due to AHR adjustment by state, local health network, hospital, and AIHW hospital peer group.`,
    },
  },

  NWAUAHRLineChartButtons: {
    options: {
      id: "JeGUtF",
      height: 75,
    }
  },

  //NWAU adjustments and AHR episodes over time
  NWAUAHRLineChart: {
    options: {
      id: "ALAKf", 
      height: 260,
    },

    table: {
      id: "HmKSnh",
      height: 260
    },

    tooltip: {
      enabled: true,
      text: "These three tabs show charts of AHR NWAU adjustments over time: (1) year comparison of quarterly AHR adjustments, (2) quarterly episodes with AHRs and total AHR adjustments and (3) AHR as a percent of total NWAU per quarter.",
    },
  },

  NWAUbyAHRGroupBarChartButtons: {
    options: {
      id: "jPDFnf",
      height: 75,
    }
  },

  NWAUbyAHRGroupBarChart: {
    options: {
      id: "jRxAEaj", 
      height: 343,
    },

    table: {
      id: "dmBPpM",
      height: 343
    },

    tooltip: {
      enabled: true,
      text: `These three tabs show graphs of AHR and NWAU adjustments by AHR readmission groups: (1) number of episodes with AHR and total AHR adjustments, (2) year-on-year comparison of NWAU adjustments due to AHRs and (3) year-on-year comparison of deduction per AHR.`,
    },
  },

  AHRgroupLegendTable: {
    options: {
      id: "FGZJ",
      height: 343,
    }
  },

  AHRRate: {
    options: {
      id: "KVVTyn",
      height: 130,
    },

    tooltip: {
      enabled: true,
      text: "Episodes satisfying the definition of at least one AHR diagnosis category per 10,000 eligible episodes (for details, see the User Guide).",
    }
  },

  AHRExpectedRate: {
    options: {
      id: "whKjtBD",
      height: 130,
    },

    tooltip: {
      enabled: true,
      text: "An estimate of the AHR rate based on the casemix of episodes satisfying the user's filter criteria. This estimate accounts for limited information and should only be used as a guide and not as a tool for evaluating performance. For details, see the User Guide.",
    }
  },

  AHRExpectedCount: {
    options: {
      id: "gMdjvAc",
      height: 130,
    },

    tooltip: {
      enabled: true,
      text: "An estimate of the number of AHRs based on the casemix of episodes satisfying the user's filter criteria. For details, see the User Guide.",
    }
  },

  AHRRateScatterChartButtons: {
    options: {
      id: "jaQd",
      height: 75,
    }
  },

  AHRRateScatterChart: {
    options: {
      id: "RNUW", 
      height: 343,
    },

    table: {
      id: "HUWvuLT",
      height: 343
    },

    tooltip: {
      enabled: true,
      text: `AHRs per 10,000 eligible episodes per state, local hospital network, hospital, or AIHW hospital peer group.`,
    },
  },

  AHRCountLineChartButtons: {
    options: {
      id: "wRhYGP",
      height: 75,
    }
  },

  AHRCountLineChart: {
    options: {
      id: "DMCUps", 
      height: 343,
    },

    table: {
      id: "jsJNRD",
      height: 343
    },

    tooltip: {
      enabled: true,
      text: `The 'Year Comparison' tab compares episodes with AHRs by quarter for each year of data. The 'Against Expected' tab compares the number of episodes with AHRs to the expected number of AHRs, by quarter.`,
    },
  },

  AHRRiskBarChart: {
    options: {
      id: "LpPBr", 
      height: 343,
    },

    table: {
      id: "RkxSxdX",
      height: 343
    },

    tooltip: {
      enabled: true,
      text: `A comparison of AHR rate between the user's chosen year and the previous, sorted by complexity group. See the User Guide for details on how these categories are defined.`,
    },
  },

  AHRbyGroupBarChart: {
    options: {
      id: "maCzzv", 
      height: 343,
    },

    table: {
      id: "TmxEjf",
      height: 343
    },

    tooltip: {
      enabled: true,
      text: `The number of AHRs within each diagnosis category compared to a modelled estimate of AHRs, based on casemix. Select an AHR category to see the number of AHRs in each subcategory, compared to the previous year.`,
    }
  },

  AHRgroupLegendTableDynamic: {
    options: {
      id: "AguWPef", 
      height: 343,
    }
  },

  AHRAveLOSLineChart: {
    options: {
      id: "MdxpE", 
      height: 343,
    },

    table: {
      id: "JSVTd",
      height: 343
    },

    tooltip: {
      enabled: true,
      text: `A comparison between the average length of stay for episodes with and without AHR.`,
    },
  },

  AHRRateAge60LineChart: {
    options: {
      id: "CtANPM", 
      height: 343,
    },

    table: {
      id: "kJPmuk",
      height: 343
    },

    tooltip: {
      enabled: true,
      text: `The proportion of patients aged over 60 years, we compare the group of episodes which result in an AHR to the group of episodes which do not result in an AHR.`,
    },
  },

  AHRRateSSOLineChart: {
    options: {
      id: "eNRD", 
      height: 343,
    },

    table: {
      id: "ejL",
      height: 343
    },

    tooltip: {
      enabled: true,
      text: `Dummy text.`,
    },
  },

  AHRCharlsonBarChart: {
    options: {
      id: "dxQSNdu", 
      height: 343,
    },

    table: {
      id: "tegxLvZ",
      height: 343
    },

    tooltip: {
      enabled: true,
      text: `The comparison of the proportion of episodes with a mild, moderate, or severe Charlson score between episodes with and without an AHR. See the User Guide for definition of Charlson Score and the groupings used.`,
    },
  },

  AHRActivityBarChartButtons: {
    options: {
      id: "edXEvp",
      height: 75,
    }
  },

  AHRActivityBarChart: {
    options: {
      id: "JYgrwR",
      height: 260,
    },

    table: {
      id: "UbjujpY",
      height: 260
    },

    tooltip: {
      enabled: true,
      text: `The proportion of AHRs contained in each AHR diagnosis category. The user can compare selected jurisdictions, LHNs, hospitals, or peer groups using the tabs above the graph.`,
    },
  },
}
