import React, { useState } from "react";
import { AppConfig } from "../config/app";

import Selections from "./Selections";

export interface FiltersPanelProps {
  app: AppConfig
}

const FiltersPanel: React.FC<FiltersPanelProps> = (props) => {
  const [showMenu, setShowMenu] = useState(false);

  return (
    <React.Fragment>
      <div className="filters-panel">
        <Selections app={props.app}/>
      </div>
    </React.Fragment>
  );
};

export default FiltersPanel;
