import {
  Row,
  Col,
  Container,
  Card,
  OverlayTrigger,
  Tooltip,
  Breadcrumb
} from "react-bootstrap";

import { app } from "../config/app";
import { Link } from "react-router-dom";
import { PalliativePhasesNote } from "../components/PalliativePhasesNote";
import { visuals } from "../config/visuals";
import Banner from "../components/Banner";
import Filters from "../components/Filters";
import FiltersPanel from "../components/FiltersPanel";
import QdtComponent from "../components/QdtComponent";
import TableOrVisualisation from "../components/TableOrVisualisation";

import styles from "./styles/Overview.module.scss"
import { costNwauLinks } from "../config/constants";
import KPI from "../components/KPI";
import Visualisation from "../components/Visualisation";

export const Overview = () => {
  const options = visuals;

  return (
    <div>
      <Breadcrumb className='breadcrumbs'>
        <Link to="/" className="breadcrumb-item ps-1">Home</Link>
        <Link to="/periodic-insights" className="breadcrumb-item">Periodic insights</Link>
        <Breadcrumb.Item active>Cost Per NWAU</Breadcrumb.Item>
      </Breadcrumb>

      <Container fluid>
        <Row>
          <Banner title="Cost per NWAU" tabs={costNwauLinks} />
        </Row>

        <Row>
          <FiltersPanel app={app} />
        </Row>

        <Row className="content">
          <Col md="1" className="border-r">
            <Filters />
          </Col>

          <Col md="8" className="border-r">
            <Row className="row1">
              <Col md="3">
                <KPI borderRight app={app} options={options.CostPerNWAUNational} />
              </Col>
              <Col md="3">
                <KPI borderRight app={app} options={options.CostPerNWAUPeerGroup} />
              </Col>
              <Col md="3">
                <KPI borderRight app={app} options={options.CostPerNWAUAllSelections} />
              </Col>
              <Col md="3">
                <KPI app={app} options={options.TotalRecordsAllSelections} />
              </Col>
            </Row>
            <Row className="margin border-b">
            </Row>
            <Row className="row2">
              <Col md="5" className="border-r">
                <Visualisation name={"CostPerNWAUBreakDown"} options={options.CostPerNWAUBreakDown} withButtons={false} app={app} />
              </Col>
              <Col md="7">
                <Visualisation name={"CostPerNWAUBarChart"} options={options.CostPerNWAUBarChart} withButtons={true} buttonOptions={options.CostPerNWAUBarChartButtons} app={app} />
              </Col>
            </Row>
          </Col>
          <Col md="3" className="border-r">
            <Row>
              <Card className="mb-5 mb-lg-0">
                <div className="title-wrap">
                  <OverlayTrigger
                    overlay={
                      <Tooltip id="tooltip-top">
                        {options.CostPerSeparationAllSelections.tooltip!.text}
                      </Tooltip>
                    }
                  >
                    <div
                      className="tool-tip"
                      style={{
                        display: options.CostPerSeparationAllSelections.tooltip!.enabled
                          ? "block"
                          : "none",
                      }}
                    ></div>
                  </OverlayTrigger>
                </div>
                <QdtComponent options={options.CostPerSeparationAllSelections.options} app={app} />
                <Row>
                  <Col xs="7" md="7">
                    <QdtComponent options={options.CostPerSeparationComparisonTitles.options} app={app} />
                  </Col>

                  <Col xs="5" md="5">
                    <QdtComponent options={options.CostPerSeparationComparisonNumbers.options} app={app} />
                  </Col>
                </Row>
                <Row>
                  <Link to="../periodic-insights/cost-detail">Explore cost detail &#x203a;</Link>
                </Row>
              </Card>
            </Row>
            <Row className="margin border-b"></Row>
            <Row>
              <Card className="mb-5 mb-lg-0">
                <div className="title-wrap">
                  <OverlayTrigger
                    overlay={
                      <Tooltip id="tooltip-top">
                        {options.NWAUPerSeparationAllSelections.tooltip!.text}
                      </Tooltip>
                    }
                  >
                    <div
                      className="tool-tip"
                      style={{
                        display: options.NWAUPerSeparationAllSelections.tooltip!.enabled
                          ? "block"
                          : "none",
                      }}
                    ></div>
                  </OverlayTrigger>
                </div>
                <QdtComponent options={options.NWAUPerSeparationAllSelections.options} app={app} />
                <Row>
                  <Col xs="7" md="7">
                    <QdtComponent options={options.NWAUPerSeparationComparisonTitles.options} app={app} />
                  </Col>

                  <Col xs="5" md="5">
                    <QdtComponent options={options.NWAUPerSeparationComparisonNumbers.options} app={app} />
                  </Col>
                </Row>
                <Row>
                  <Link to="../periodic-insights/nwau-detail">Explore NWAU detail &#x203a;</Link>
                </Row>
              </Card>
            </Row>
          </Col>
        </Row>

        <Row>
          <PalliativePhasesNote />
        </Row>
      </Container>
    </div>
  );
};
