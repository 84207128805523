import {
  Row,
  Col,
  Container,
  Card,
  OverlayTrigger,
  Tooltip,
  Breadcrumb
} from "react-bootstrap";
import { Link } from "react-router-dom";

import { hacApp } from "../config/app";
import { visuals } from "../config/visuals";
import { HACFootNote } from "../components/HACFootnote";
import Banner from "../components/Banner";
import HacFilters from "../components/HacFilters";
import FiltersPanel from "../components/FiltersPanel";
import QdtComponent from "../components/QdtComponent";
import TableOrVisualisation from "../components/TableOrVisualisation";
import { PalliativePhasesNote } from "../components/PalliativePhasesNote";
import { hospitalComplicationsLinks } from "../config/constants";
import KPI from "../components/KPI";
import Visualisation from "../components/Visualisation";

export const HACActivity = () => {
  const options = visuals;

  return (
    <div>
      <Breadcrumb className="breadcrumbs">
        <Link to="/" className="breadcrumb-item ps-1">Home</Link>
        <Link to="/periodic-insights" className="breadcrumb-item">Periodic insights</Link>
        <Breadcrumb.Item active>Hospital Acquired Complications</Breadcrumb.Item>
      </Breadcrumb>

      <Container fluid>
        <Row>
          <Banner title="Hospital Acquired Complications" tabs={hospitalComplicationsLinks} />
        </Row>

        <Row>
          <FiltersPanel app={hacApp} />
        </Row>

        <Row className="content ">
          <Col md="1" className="border-r mb-5 mb-lg-0">
            <HacFilters />
          </Col>

          <Col>
            <Row className="border-b">
              <Col className="mb-2">
                <KPI borderRight app={hacApp} options={options.HACRate} />
              </Col>

              <Col className="pe-0">
                <KPI borderRight app={hacApp} options={options.ExpectedHACRate} />
              </Col>

              <Col>
                <KPI borderRight app={hacApp} options={options.HACCount} />
              </Col>

              <Col>
                <KPI borderRight app={hacApp} options={options.ExpectedHACCount} />
              </Col>

              <Col>
                <KPI app={hacApp} options={options.HACEpisodes} />
              </Col>
            </Row>

            <Row className="border-b">
              <Col md="6" className="border-r">
                {/* hac rate */}
                <Visualisation
                  name={"HACRateBarChart"}
                  options={options.HACRateBarChart}
                  withButtons={true}
                  buttonOptions={options.HACRateBarChartButtons}
                  app={hacApp} />
              </Col>

              <Col md="6">
                {/* nwau and episodes with hacs over time */}
                <Visualisation
                  name={"HACEpisodesLineChart"}
                  options={options.HACEpisodesLineChart}
                  withButtons={true}
                  buttonOptions={options.HACEpisodesLineChartButtons}
                  app={hacApp} />
              </Col>
            </Row>

            <Row>
              <Col className="border-r">
                {/* hac rate by risk complexity group */}
                <Visualisation
                  name={"HACRiskCateoryChart"}
                  options={options.HACRiskCateoryChart}
                  withButtons={false}
                  app={hacApp} />
              </Col>

              <Col md="6">
                {/* hac by group */}
                <Visualisation
                  name={"HACbyGroupBarChart"}
                  options={options.HACbyGroupBarChart}
                  withButtons={false}
                  app={hacApp} />
              </Col>

              <Col>
                {/* hac categories */}
                <Visualisation
                  name={"HACbyGroupLegendTable"}
                  options={options.HACbyGroupLegendTable}
                  withButtons={false}
                  showFlipButton={false}
                  showHoverIcon={false}
                  app={hacApp} />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <HACFootNote />
        </Row>
      </Container>
    </div>
  );
};

