import { useState } from 'react'

import { app } from "../config/app";
import QdtComponent from "../components/QdtComponent";
import { visuals } from "../config/visuals";


type VisualsKey = keyof typeof visuals;

export interface PropsTableOrVisualisation {
  visualisation: VisualsKey
  app: any
  showFlipButton?: boolean
}

const TableOrVisualisation: React.FC<PropsTableOrVisualisation> = (props) => {
  const [showTable, setShowTable] = useState<boolean>(false);
  const [buttonName, setButtonName] = useState<string>('Show table')

  const getViz = visuals[props.visualisation]

  const handleOnClick = () => {
    setShowTable(prev => !prev)

    setButtonName(prev =>
      prev === 'Show table' ?
        'Show visualisation'
        :
        'Show table'
    )
  }
  
  return (
    <>
      {showTable ?
        <QdtComponent options={getViz.table!} exportData={true} app={props.app} />
        :
        <QdtComponent options={getViz.options} app={props.app} />
      }
      {props.showFlipButton && <button onClick={handleOnClick}>{buttonName}</button>}
    </>
  )
}

TableOrVisualisation.defaultProps = {
  showFlipButton: true,
};

export default TableOrVisualisation
