import QdtComponent from "../components/QdtComponent"
import { useLocation } from "react-router";

import { app } from "../config/app";
import { visuals } from "../config/visuals";


const Filters = () => {
  const options = visuals;
  const routeLocation = useLocation();

  return (
    <>
      <h6>Filters</h6>
      <QdtComponent options={options.YearFilter.options} app={app} />
      <QdtComponent options={options.StateFilter.options} app={app} />
      <QdtComponent options={options.LHNFilter.options} app={app} />
      <QdtComponent options={options.HospitalFilter.options} app={app} />
      <QdtComponent options={options.StreamFilter.options} app={app} />
      <QdtComponent options={options.PeerGroupFilter.options} app={app} />

      {/* {routeLocation.pathname === "/periodic-insights/overview" ? */}
      <QdtComponent options={options.ClassificationFilter.options} app={app} />
        {/* :
        ""
      } */}

      {/* {(routeLocation.pathname === "/periodic-insights/overview" || routeLocation.pathname === "/periodic-insights/cost-detail") ?
        <QdtComponent options={options.CostDirectOrOverHeadFilter.options} app={app} />
        :
        ""
      } */}

      {/* {routeLocation.pathname === "/periodic-insights/overview" ?
        <QdtComponent options={options.CostFilter.options} app={app} />
        :
        <QdtComponent options={options.ClassificationFilter.options} app={app} />
      } */}
      
      <QdtComponent options={options.PrincipalDiagnosisFilter.options} app={app} />
      <QdtComponent options={options.SRGFilter.options} app={app} />
    </>
  );
};

export default Filters;
