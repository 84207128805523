import {
  Row,
  Col,
  Container,
  Card,
  OverlayTrigger,
  Tooltip,
  Breadcrumb
} from "react-bootstrap";
import { Link } from "react-router-dom";

import { ahrApp } from "../config/app";
import { visuals } from "../config/visuals";
import { AHRFootNote } from "../components/AHRFootnote";
import Banner from "../components/Banner";
import AhrFilters from "../components/AhrFilters";
import FiltersPanel from "../components/FiltersPanel";
import { ahrLinks } from "../config/constants";
import KPI from "../components/KPI";
import Visualisation from "../components/Visualisation";

export const AHRTrends = () => {
  const options = visuals;

  return (
    <div>
      <Breadcrumb className="breadcrumbs">
        <Link to="/" className="breadcrumb-item ps-1">Home</Link>
        <Link to="/periodic-insights" className="breadcrumb-item">Periodic insights</Link>
        <Breadcrumb.Item active>Avoidable Hospital Readmissions</Breadcrumb.Item>
      </Breadcrumb>

      <Container fluid>
        <Row>
          <Banner title="Avoidable Hospital Readmissions" tabs={ahrLinks} />
        </Row>

        <Row>
          <FiltersPanel app={ahrApp} />
        </Row>

        <Row className="content ">
          <Col md="1" className="border-r mb-5 mb-lg-0">
            <AhrFilters />
          </Col>

          <Col className="ms-2">
            <Row className="border-b">
              <Col className="border-r mb-2">
                {/* number of ahr */}
                <KPI app={ahrApp} options={options.AHRCount} />
              </Col>

              <Col className="pe-0 border-r mb-2">
                {/* ahr rate */}
                <KPI app={ahrApp} options={options.AHRRate} />
              </Col>

              <Col className="pe-0 mb-2">
                {/* total episodes */}
                <KPI app={ahrApp} options={options.AHRSeparations} />
              </Col>
            </Row>

            <Row className="border-b pb-2">
              <Col md="6" className="border-r mt-2">
                {/* average length of stay */}
                <Visualisation
                  withButtons={false}
                  name={"AHRAveLOSLineChart"}
                  options={options.AHRAveLOSLineChart}
                  app={ahrApp} />
              </Col>
              <Col md="6" className="border-r mt-2">
                {/* rate of age greater than 60 */}
                <Visualisation
                  name={"AHRRateAge60LineChart"}
                  options={options.AHRRateAge60LineChart}
                  withButtons={false}
                  app={ahrApp} />
              </Col>
            </Row>
            <Row>
              <Col md="4" className="border-r mt-2">
                {/* charlson score */}
                <Visualisation
                  name={"AHRCharlsonBarChart"}
                  options={options.AHRCharlsonBarChart}
                  withButtons={false}
                  app={ahrApp} />
              </Col>
              <Col md="8" className="mt-2">
                {/* % of ahr activity */}
                <Visualisation
                  name={"AHRActivityBarChart"}
                  options={options.AHRActivityBarChart}
                  withButtons={true}
                  buttonOptions={options.AHRActivityBarChartButtons}
                  app={ahrApp} />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <AHRFootNote />
        </Row>
      </Container>
    </div>
  );
};

