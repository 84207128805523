import { useCallback, useContext, useEffect, useRef } from "react";
import {
  qdtEnigma,
  qdtCapabilityApp,
  qdtCompose,
  QdtViz,
} from "qdt-components";
import PropTypes from "prop-types";
import { QlikConnectionContext } from "../utils/QlikConnectionContext"


const identity = Math.random().toString(32).substr(2, 8);

function QdtComponent({
  component,
  properties,
  options,
  appIndex,
  exportData,
  app,
}: {
  component: any;
  properties: any;
  options: any;
  appIndex: any;
  exportData: boolean;
  app: any;
}) {
  const config = {
    ...app.config,
    identity,
  };

  const {engineApiAppPromise, capabilityApiAppPromise} = useContext(QlikConnectionContext);

  // const engineApiAppPromise = qdtEnigma(config);
  // const capabilityApiAppPromise = qdtCapabilityApp(config);

  const elementRef = useRef(null);

  const handleExportData = useCallback(async () => {
    const app = await capabilityApiAppPromise;
    app.visualization.get(options.id).then((vis: any) => {
      console.log(vis);
      vis.exportData({ format: "CSV_C", state: "P" }).then((link: string) => {
        const tempLink: string = link.replace(':443:443', ':443');
        window.open(tempLink, '_blank');
      })
    })
  }, [capabilityApiAppPromise, options]);

  const init = async () => {
    let app = await engineApiAppPromise;

    if (appIndex === 1) {
      qdtCompose({
        element: elementRef.current,
        component,
        app,
        properties,
        options,
      });
    } else {
      app = await capabilityApiAppPromise;

      const theme = await app.theme.get('ihpa_theme')

      theme.apply()

      QdtViz({
        element: elementRef.current,
        app,
        options,
        properties,
      });
    }
  };

  useEffect(() => {
    if (elementRef) init();
  }, [init]);

  return <>
    <div ref={elementRef} />
    {exportData &&
      <button type="button" onClick={handleExportData}>Export data</button>
    }
  </>;
}

QdtComponent.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  properties: PropTypes.object,
  options: PropTypes.object,
  appIndex: PropTypes.number,
  exportData: PropTypes.bool,
};
QdtComponent.defaultProps = {
  component: null,
  properties: {},
  options: {},
  appIndex: 2,
  exportData: false,
};

export default QdtComponent;
