import {
  Row,
  Col,
  Container,
  Card,
  OverlayTrigger,
  Tooltip,
  Breadcrumb
} from "react-bootstrap";
import { Link } from "react-router-dom";

import { app, hacApp } from "../config/app";
import { visuals } from "../config/visuals";
import { HACFootNote } from "../components/HACFootnote";
import Banner from "../components/Banner";
import HacFilters from "../components/HacFilters";
import FiltersPanel from "../components/FiltersPanel";
import QdtComponent from "../components/QdtComponent";
import TableOrVisualisation from "../components/TableOrVisualisation";
import { PalliativePhasesNote } from "../components/PalliativePhasesNote";
import { hospitalComplicationsLinks } from "../config/constants";
import KPI from "../components/KPI";
import Visualisation from "../components/Visualisation";
import { useEffect } from "react";

export const NWAUAdjustments = () => {
  const options = visuals;

  return (
    <div>
      <Breadcrumb className="breadcrumbs">
        <Link to="/" className="breadcrumb-item ps-1">Home</Link>
        <Link to="/periodic-insights" className="breadcrumb-item">Periodic insights</Link>
        <Breadcrumb.Item active>Hospital Acquired Complications</Breadcrumb.Item>
      </Breadcrumb>

      <Container fluid>
        <Row>
          <Banner title="Hospital Acquired Complications" tabs={hospitalComplicationsLinks} />
        </Row>

        <Row>
          <FiltersPanel app={hacApp} />
        </Row>

        <Row className="content ">
          <Col md="1" className="border-r mb-5 mb-lg-0">
            <HacFilters />
          </Col>

          <Col className="ms-2">
            <Row className="border-b">
              <Col md="3" className="border-r mb-2">
                <KPI app={hacApp} options={options.NWAUAdjustments} />
              </Col>

              <Col md="3" className="pe-0 border-r mb-2">
                <KPI app={hacApp} options={options.PercentageAdjustments} />
              </Col>

              <Col md="3" className="border-r mb-2">
                <KPI app={hacApp} options={options.HACCount} />
              </Col>

              <Col md="3">
                <KPI app={hacApp} options={options.HACEpisodes} />
              </Col>
            </Row>

            <Row className="border-b pb-2">
              <Col md="6" className="border-r mt-2">
                <Visualisation name={"NWAUHACScatterChart"} options={options.NWAUHACScatterChart} withButtons={true} buttonOptions={options.NWAUHACScatterChartButtons} app={hacApp} />
              </Col>
              <Col md="6" className="border-r mt-2">
                <Visualisation name={"NWAUandHACLineChart"} options={options.NWAUandHACLineChart} withButtons={true} buttonOptions={options.NWAUandHACLineChartButtons} app={hacApp} />
              </Col>
            </Row>
            <Row>
              <Col md="9">
                <Visualisation name={"NWAUbyHACGroupBarChart"} options={options.NWAUbyHACGroupBarChart} withButtons={true} buttonOptions={options.NWAUbyHACGroupBarChartButtons} app={hacApp} />
              </Col>
              <Col md="3" className="border-r mt-2">
                <Visualisation showHoverIcon={false} name={"HACgroupLegendTable"} options={options.HACgroupLegendTable} withButtons={false} app={hacApp} showFlipButton={false}/>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <HACFootNote />
        </Row>
      </Container>
    </div>
  );
};

