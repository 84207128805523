import {
  Row,
  Col,
  Container,
  Card,
  OverlayTrigger,
  Tooltip,
  Breadcrumb
} from "react-bootstrap";
import { Link } from "react-router-dom";

import { ahrApp } from "../config/app";
import { visuals } from "../config/visuals";
import { AHRFootNote } from "../components/AHRFootnote";
import Banner from "../components/Banner";
import AhrFilters from "../components/AhrFilters";
import FiltersPanel from "../components/FiltersPanel";
import { ahrLinks } from "../config/constants";
import KPI from "../components/KPI";
import Visualisation from "../components/Visualisation";

export const AHRActivity = () => {
  const options = visuals;

  return (
    <div>
      <Breadcrumb className="breadcrumbs">
        <Link to="/" className="breadcrumb-item ps-1">Home</Link>
        <Link to="/periodic-insights" className="breadcrumb-item">Periodic insights</Link>
        <Breadcrumb.Item active>Avoidable Hospital Readmissions</Breadcrumb.Item>
      </Breadcrumb>

      <Container fluid>
        <Row>
          <Banner title="Avoidable Hospital Readmissions" tabs={ahrLinks} />
        </Row>

        <Row>
          <FiltersPanel app={ahrApp} />
        </Row>

        <Row className="content ">
          <Col md="1" className="border-r mb-5 mb-lg-0">
            <AhrFilters />
          </Col>

          <Col className="ms-2">
            <Row className="border-b">
              <Col className="border-r mb-2">
                <KPI app={ahrApp} options={options.AHRRate} />
              </Col>

              <Col className="pe-0 border-r mb-2">
                <KPI app={ahrApp} options={options.AHRExpectedRate} />
              </Col>

              <Col className="border-r mb-2">
                <KPI app={ahrApp} options={options.AHRCount} />
              </Col>

              <Col className="border-r mb-2">
                <KPI app={ahrApp} options={options.AHRExpectedCount} />
              </Col>

              <Col>
                <KPI app={ahrApp} options={options.AHRSeparations} />
              </Col>
            </Row>

            <Row className="border-b pb-2">
              <Col md="6" className="border-r mt-2">
                <Visualisation
                  name={"AHRRateScatterChart"}
                  options={options.AHRRateScatterChart}
                  withButtons={true}
                  buttonOptions={options.AHRRateScatterChartButtons}
                  app={ahrApp} />
              </Col>
              <Col md="6" className="border-r mt-2">
                <Visualisation
                  name={"AHRCountLineChart"}
                  options={options.AHRCountLineChart}
                  withButtons={true}
                  buttonOptions={options.AHRCountLineChartButtons}
                  app={ahrApp} />
              </Col>
            </Row>
            <Row>
              <Col className="border-r">
                {/* ahr rate by risk */}
                <Visualisation
                  name={"AHRRiskBarChart"}
                  options={options.AHRRiskBarChart}
                  withButtons={false}
                  app={ahrApp} />
              </Col>

              <Col md="6">
                {/* ahr by group */}
                <Visualisation
                  name={"AHRbyGroupBarChart"}
                  options={options.AHRbyGroupBarChart}
                  withButtons={false}
                  app={ahrApp} />
              </Col>
              
              <Col >
              {/* md="9" className="mt-2" */}
                {/* ahr by complication group */}
                <Visualisation
                  name={"AHRgroupLegendTableDynamic"}
                  options={options.AHRgroupLegendTableDynamic}
                  withButtons={false}
                  app={ahrApp}
                  showFlipButton={false}
                  showHoverIcon={false}
                  />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <AHRFootNote />
        </Row>
      </Container>
    </div>
  );
};

