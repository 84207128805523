import { Nav, Navbar, Row } from "react-bootstrap";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useLocation } from "react-router";

import LogoWhite from "../images/navLogo.svg";
import styles from "./styles/Navigation.module.scss"

const Navigation = () => {
  const routeLocation = useLocation();
  return (
    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" className={`${styles['navbar']}`}>
      <Container fluid>
        <Link to="/" className={`${styles['logo']}`}>
          <Navbar.Brand><img width="345px" height="auto" src={LogoWhite} alt="IHACPA" className={`${styles['logo-img']} whitelogo`} /></Navbar.Brand>
        </Link>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-auto">
            <Link
              to="/periodic-insights"
              className={
                `fancy-underline`
              }>
              Periodic insights
            </Link>

            <Link
              to="/periodic-insights/glossary"
              className={
                `fancy-underline`
              }>
              Glossary
            </Link>

            <a
              className="fancy-underline"
              color="white"
              href={"https://www.ihacpa.gov.au/sites/default/files/2023-05/National%20Benchmarking%20Portal%20-%20User%20Guide_2.PDF"}
            >
              User Guide
            </a>

            <a
              className="fancy-underline"
              color="white"
              href="mailto:enquiries.ihpa@ihpa.gov.au"
            >
              Contact
            </a>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    // <Navbar className={`${styles['navbar']}`}>
    //   <Container fluid>
    //     <Link to="/" className={`${styles['logo']}`}>
    //       <img src={LogoWhite} alt="IHACPA" className={`${styles['logo-img']} whitelogo`} />
    //     </Link>
    //     <Navbar className={`justify-content-end pe-4`} expand="md">
    //       <Navbar.Collapse>
    // <Link
    //   to="/periodic-insights"
    //   className={
    //     `fancy-underline`
    //   }>
    //   Periodic insights
    // </Link>

    // <a
    //   className="fancy-underline"
    //   color="white"
    //   href={"https://www.ihacpa.gov.au/sites/default/files/2022-08/national_benchmarking_portal_-_user_guide.pdf"}
    // >
    //   User Guide
    // </a>

    // <a
    //   className="fancy-underline"
    //   color="white"
    //   href="mailto:enquiries.ihpa@ihpa.gov.au"
    // >
    //   Contact
    // </a>
    //       </Navbar.Collapse>

    //     </Navbar>
    //   </Container>
    // </Navbar>
  );
};

export default Navigation;
