import {
  Row,
  Col,
  Container,
  Card,
  OverlayTrigger,
  Tooltip,
  Breadcrumb
} from "react-bootstrap";
import { Link } from "react-router-dom";
import QdtComponent from "../components/QdtComponent";

import { app } from "../config/app";
import { PalliativePhasesNote } from "../components/PalliativePhasesNote";
import { visuals } from "../config/visuals";
import Banner from "../components/Banner";
import Filters from "../components/Filters";
import FiltersPanel from "../components/FiltersPanel";
import TableOrVisualisation from "../components/TableOrVisualisation";
import styles from './styles/CostDetail.module.scss'
import { costNwauLinks } from "../config/constants";
import KPI from "../components/KPI";
import Visualisation from "../components/Visualisation";


export const CostDetail = () => {
  const options = visuals;

  return (
    <div>
      <Breadcrumb className="breadcrumbs">
        <Link to="/" className="breadcrumb-item ps-1">Home</Link>
        <Link to="/periodic-insights" className="breadcrumb-item">Periodic insights</Link>
        <Breadcrumb.Item active>Cost Per NWAU</Breadcrumb.Item>
      </Breadcrumb>

      <Container fluid>
        <Row>
          <Banner title="Cost per NWAU" tabs={costNwauLinks} />
        </Row>

        <Row>
          <FiltersPanel app={app} />
        </Row>

        <Row className="content ">
          <Col md="1" className="border-r">
            <Filters />
          </Col>

          <Col >
            <Row >
              <Col xs="12" md>
                <KPI borderRight app={app} options={options.CostPerNWAUKPI} />
              </Col>

              <Col xs="12" md>
                <KPI borderRight app={app} options={options.CostPerNWAUPeerGroupKPI} />
              </Col>

              <Col xs="12" md className="mb-5 mb-md-0">
                <KPI borderRight app={app} options={options.CostPerNWAUAllSelectionsKPI} />
              </Col>

              {/* Total NWAU - All Selections*/}
              <Col xs="12" md className="mb-5 mb-md-0">
                <KPI borderRight app={app} options={options.TotalCostKPI} />
              </Col>

              {/* Total Separations - All Selections */}
              <Col>
                <KPI app={app} options={options.TotalRecordsAllSelections} />
              </Col>
            </Row>

            <Row className="border-b margin"></Row>

            <Row>
              {/* NWAU per Separation Comparison */}
              <Col >
                <Visualisation name={"CostPerNWAUScatterChart"} options={options.CostPerNWAUScatterChart} withButtons={true} buttonOptions={options.CostPerNWAUScatterChartButtons} app={app} />
              </Col>

              <Col>
                <Row>
                  <Visualisation name={"CostPerNWAUBarChartCostDetail"} options={options.CostPerNWAUBarChartCostDetail} withButtons={true} buttonOptions={options.CostPerNWAUCostBucketSelection} app={app} />
                </Row>
                <Row>
                  <Card>
                    <div className="title-wrap">
                      <OverlayTrigger
                        overlay={
                          <Tooltip id="tooltip-top">
                            {options.CostPerNWAULineChart.tooltip!.text}
                          </Tooltip>
                        }
                      >
                        <div
                          className="tool-tip"
                          style={{
                            display: options.CostPerNWAULineChart.tooltip!.enabled
                              ? "block"
                              : "none",
                          }}
                        ></div>
                      </OverlayTrigger>
                    </div>
                    <div className="object-wrap">
                      <div className="sm-btn">
                        <QdtComponent options={options.CostPerNWAULineChartButtons.options} app={app} />
                      </div>
                      <TableOrVisualisation visualisation="CostPerNWAULineChart" app={app} />
                    </div>
                  </Card>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <PalliativePhasesNote />
        </Row>
      </Container>
    </div>
  );
};