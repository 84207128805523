import QdtComponent from "./QdtComponent"
import { useLocation } from "react-router";

import { hacApp } from "../config/app";
import { visuals } from "../config/visuals";


const HacFilters = () => {
  const options = visuals;
  const routeLocation = useLocation();

  return (
    <>
      <h6>Filters</h6>
      <QdtComponent options={options.YearFilterHAC.options} app={hacApp} />
      <QdtComponent options={options.MonthFilterHAC.options} app={hacApp} />
      <QdtComponent options={options.StateFilterHAC.options} app={hacApp} />
      <QdtComponent options={options.LHNFilterHAC.options} app={hacApp} />
      <QdtComponent options={options.HospitalFilterHAC.options} app={hacApp} />
      <QdtComponent options={options.PeerGroupFilterHAC.options} app={hacApp} />
      <QdtComponent options={options.ComplicationGroupFilterHAC.options} app={hacApp} />
      <QdtComponent options={options.AgeFilterHAC.options} app={hacApp} />
    </>
  );
};

export default HacFilters;
