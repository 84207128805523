import { HashRouter as Router, Switch, Route } from "react-router-dom";

import { CostDetail } from "./views/CostDetail"
import { NWAUDetail } from "./views/NWAUDetail";
import { Overview } from "./views/Overview";
import { PIMenu } from "./views/PIMenu";
import Footer from "./components/Footer";
import Glossary from "./views/Glossary";
import Home from "./views/Home";
import Navigation from "./components/Navigation";
import { NWAUAdjustments } from "./views/NWAUAdjustments";
import { HACActivity } from "./views/HACActivity";
import { HACTrends } from "./views/HACTrends";
import { AHRNWAUAdjustments } from "./views/AHRNWAUAdjustments";
import { AHRActivity } from "./views/AHRActivity";
import { AHRTrends } from "./views/AHRTrends";
import { GA_TRACKING_ID } from "./config/constants";
import ReactGA from 'react-ga4';
import { app, hacApp, ahrApp } from './config/app';
import {
  qdtEnigma,
  qdtCapabilityApp
} from "qdt-components";
import { QlikConnectionContext } from "./utils/QlikConnectionContext";

ReactGA.initialize(GA_TRACKING_ID);

const App = () => {
  const nwauIdentity = Math.random().toString(32).substr(2, 8);
  const nwauConfig = {
    ...app.config,
    nwauIdentity,
  };
  const nwauEngineApiAppPromise = qdtEnigma(nwauConfig);
  const nwauCapabilityApiAppPromise = qdtCapabilityApp(nwauConfig);

  const hacIdentity = Math.random().toString(32).substr(2, 8);
  const hacConfig = {
    ...hacApp.config,
    hacIdentity,
  };
  const hacEngineApiAppPromise = qdtEnigma(hacConfig);
  const hacCapabilityApiAppPromise = qdtCapabilityApp(hacConfig);

  const ahrIdentity = Math.random().toString(32).substr(2, 8);
  const ahrConfig = {
    ...ahrApp.config,
    ahrIdentity,
  };
  const ahrEngineApiAppPromise = qdtEnigma(ahrConfig);
  const ahrCapabilityApiAppPromise = qdtCapabilityApp(ahrConfig);

  return (
    <div id="root" className="app">
      <Router>
        <Navigation />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/home" component={Home} />
          <Route exact path="/periodic-insights" component={PIMenu} />

          <Route exact path="/periodic-insights/nwau-detail">
            <QlikConnectionContext.Provider value={{ engineApiAppPromise: nwauEngineApiAppPromise, capabilityApiAppPromise: nwauCapabilityApiAppPromise }}>
              <NWAUDetail />
            </QlikConnectionContext.Provider>
          </Route>

          <Route exact path="/periodic-insights/overview">
            <QlikConnectionContext.Provider value={{ engineApiAppPromise: nwauEngineApiAppPromise, capabilityApiAppPromise: nwauCapabilityApiAppPromise }}>
              <Overview />
            </QlikConnectionContext.Provider>
          </Route>

          <Route exact path="/periodic-insights/cost-detail">
            <QlikConnectionContext.Provider value={{ engineApiAppPromise: nwauEngineApiAppPromise, capabilityApiAppPromise: nwauCapabilityApiAppPromise }}>
              <CostDetail />
            </QlikConnectionContext.Provider>
          </Route>

          <Route exact path="/periodic-insights/nwau-adjustments">
            <QlikConnectionContext.Provider value={{ engineApiAppPromise: hacEngineApiAppPromise, capabilityApiAppPromise: hacCapabilityApiAppPromise }}>
              <NWAUAdjustments />
            </QlikConnectionContext.Provider>
          </Route>

          <Route exact path="/periodic-insights/hac-activity">
            <QlikConnectionContext.Provider value={{ engineApiAppPromise: hacEngineApiAppPromise, capabilityApiAppPromise: hacCapabilityApiAppPromise }}>
              <HACActivity />
            </QlikConnectionContext.Provider>
          </Route>

          <Route exact path="/periodic-insights/hac-trends">
            <QlikConnectionContext.Provider value={{ engineApiAppPromise: hacEngineApiAppPromise, capabilityApiAppPromise: hacCapabilityApiAppPromise }}>
              <HACTrends />
            </QlikConnectionContext.Provider>
          </Route>

          <Route exact path="/periodic-insights/ahr-nwau-adjustments">
            <QlikConnectionContext.Provider value={{ engineApiAppPromise: ahrEngineApiAppPromise, capabilityApiAppPromise: ahrCapabilityApiAppPromise }}>
              <AHRNWAUAdjustments />
            </QlikConnectionContext.Provider>
          </Route>

          <Route exact path="/periodic-insights/ahr-activity">
            <QlikConnectionContext.Provider value={{ engineApiAppPromise: ahrEngineApiAppPromise, capabilityApiAppPromise: ahrCapabilityApiAppPromise }}>
              <AHRActivity />
            </QlikConnectionContext.Provider>
          </Route>

          <Route exact path="/periodic-insights/ahr-trends">
            <QlikConnectionContext.Provider value={{ engineApiAppPromise: ahrEngineApiAppPromise, capabilityApiAppPromise: ahrCapabilityApiAppPromise }}>
              <AHRTrends />
            </QlikConnectionContext.Provider>
          </Route>

          <Route exact path="/periodic-insights/glossary" component={Glossary} />

        </Switch>
        <Footer />
      </Router>
    </div>

  );
};

export default App;
