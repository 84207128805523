import QdtComponent from "./QdtComponent"
import { useLocation } from "react-router";

import { ahrApp } from "../config/app";
import { visuals } from "../config/visuals";


const AhrFilters = () => {
  const options = visuals;
  const routeLocation = useLocation();

  return (
    <>
      <h6>Filters</h6>
      <QdtComponent options={options.YearFilterAHR.options} app={ahrApp} />
      <QdtComponent options={options.MonthFilterAHR.options} app={ahrApp} />
      <QdtComponent options={options.StateFilterAHR.options} app={ahrApp} />
      <QdtComponent options={options.LHNFilterAHR.options} app={ahrApp} />
      <QdtComponent options={options.HospitalFilterAHR.options} app={ahrApp} />
      <QdtComponent options={options.PeerGroupFilterAHR.options} app={ahrApp} />
      <QdtComponent options={options.ReadmissionGroupFilterAHR.options} app={ahrApp} />
      <QdtComponent options={options.AgeFilterAHR.options} app={ahrApp} />
    </>
  );
};

export default AhrFilters;
