import React from "react";
import ReactDOM from "react-dom";

import "./scss/fonts.css";
import "./scss/styles.scss";
import App from "./App";


ReactDOM.render(
  <React.StrictMode>
     <App/>
  </React.StrictMode>,
  document.getElementById("root")
);