import { Container, Row, Navbar, Col, Card, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useLocation } from "react-router";
import React from "react";

import Export from "../images/export.svg";
import styles from "./styles/Banner.module.scss"
import QdtComponent from "./QdtComponent";
import { VisualisationShape } from "../config/visuals";
import { AppConfig } from "../config/app";

export interface KPIProps {
  options: VisualisationShape;
  borderRight?: boolean;
  app: AppConfig;
}

const KPI: React.FC<KPIProps> = ({ options, borderRight, app }) => {
  const routeLocation = useLocation();

  return (
    <Card className={`${borderRight ? "border-r" : ""} mb-5 mb-md-0`}>
      <div className="title-wrap">
        <OverlayTrigger
          overlay={
            <Tooltip id="tooltip-top">
              {options.tooltip!.text}
            </Tooltip>
          }
        >
          <div
            className="tool-tip"
            style={{
              display: options.tooltip!.enabled
                ? "block"
                : "none",
            }}
          ></div>
        </OverlayTrigger>
      </div>
      <div className="object-wrap">
        <QdtComponent options={options.options} app={app} />
      </div>
    </Card>
  );
};

export default KPI;