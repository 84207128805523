import { createContext } from 'react';
import { app } from '../config/app';
import {
  qdtEnigma,
  qdtCapabilityApp
} from "qdt-components";

const identity = Math.random().toString(32).substr(2, 8);

const config = {
  ...app.config,
  identity,
};

const engineApiAppPromise = qdtEnigma(config);
const capabilityApiAppPromise = qdtCapabilityApp(config);

export const QlikConnectionContext = createContext({
  engineApiAppPromise,
  capabilityApiAppPromise
});